import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { AuthGuard } from './shared/auth/auth-guard.service';

import { NotFoundLayoutComponent } from './layouts/not-found-layout/not-found-layout.component';
// const routes: Routes = [];

const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: HomeLayoutComponent ,
    children: [
      {
        path: 'home',
        loadChildren: './home/home.module#HomeModule',
      },
    ],
    
  },
  {
    path: '',
    component: HomeLayoutComponent ,
    children: [
      {
        path: 'register',
        loadChildren: './register/register.module#RegisterModule',
      },
    ]
  },
  {
    path: '',
    component: HomeLayoutComponent ,
    children: [
      {
        path: 'connexion',
        loadChildren: './connexion/connexion.module#ConnexionModule',
      },
    ]
  },
  {
    path: '',
    component: HomeLayoutComponent ,
    children: [
      {
        path: 'savoir-plus',
        loadChildren: './savoir-plus/savoir-plus.module#SavoirPlusModule',
      },
    ]
  },
  {
    path: '',
    component: HomeLayoutComponent ,
    children: [
      {
        path: 'politique-de-confidentialite',
        loadChildren: './confidentiality/confidentiality.module#ConfidentialityModule',
      },
    ]
  },
  {
    path: '',
    component: HomeLayoutComponent ,
    children: [
      {
        path: 'termes-et-conditions',
        loadChildren: './terms-and-conditions/terms-and-conditions.module#TermsAndConditionsModule',
      },
    ]
  },
  {
    path: '',
    component: HomeLayoutComponent ,
    children: [
      {
        path: 'contact',
        loadChildren: './contact/contact.module#ContactModule'
      },
    ]
  },
  {
    path: '',
    component: NotFoundLayoutComponent,
    children: [
      {
        path: '404',
        loadChildren: './not-found/not-found.module#NotFoundModule'
      },
    ]
  },
  {
    path: '',
    component: DashboardLayoutComponent ,
    canActivateChild: [ AuthGuard ],
    children: [
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
        data:  { permissions:  [
          'student', 'parent', 'demo'
          ]
        }
      },
      {
        path: 'test',
        loadChildren: './test/test.module#TestModule',
        data:  { permissions:  [
          'student', 'demo'
          ]
        }
      },
      {
        path: 'statistiques',
        loadChildren: './report/report.module#ReportModule',
        data:  { permissions:  [
          'student', 'parent', 'demo'
          ]
        }
      },
      {
        path: 'marche',
        loadChildren: './market/market.module#MarketModule',
        data: { permissions:  [
          'student'
          ]
        }
      },
      {
        path: 'commandes',
        loadChildren: './commandes/commandes.module#CommandesModule',
        data: { permissions:  [
            'student'
          ]
        }
      },
      {
        path: 'parametres',
        loadChildren: './parameters/parameters.module#ParametersModule',
        data:  { permissions:  [
            'student'
          ]
        }
      },
      {
        path: 'payement',
        loadChildren: './payement/payement.module#PayementModule',
        data:  { permissions:  [
            'student','parent'
          ]
        }
      }
    ]
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {}

