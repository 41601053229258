
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import { Observable} from 'rxjs/index';
import {Injectable} from '@angular/core';
import {User} from '../user/model/user.model';
import {AuthService} from './auth.service';
import { UserService } from '../user/user.service';
@Injectable()

export class AuthGuard implements CanActivate, CanActivateChild {
  currentUser:any;
  constructor(private authService: AuthService, private router: Router,
     private userService: UserService) {
      this.currentUser = this.userService.getCurrentUser();
      this.userService.currentUser.subscribe(
        (user: User) => {
          console.log('Current user updated on auth guard');
          this.currentUser = Object.assign({}, user);
        }
      );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log('can activate');
    if (this.authService.isAuthenticated()) {
      if (route.data !== null && route.data !== undefined) {
        if (route.data.permissions !== null && route.data.permissions !== undefined) {
          if (route.data.permissions.includes(this.currentUser.data.accountType)) {
            return true;
          } else {
            return false;
          }
        }
      }
        return true;
    }else {
      this.router.navigate(['/home']);
    }
  }

  canActivateChild( childRoute: ActivatedRouteSnapshot,
                    state: RouterStateSnapshot): Observable<boolean> |
                     Promise<boolean> | boolean {
      console.log('can activate child');
      if (this.authService.isAuthenticated()) {
        if (childRoute.data !== null && childRoute.data !== undefined) {
          if (childRoute.data.permissions !== null && childRoute.data.permissions !== undefined) {
            if (childRoute.data.permissions.includes(this.currentUser.data.accountType)) {
              return true;
            } else {
              return false;
            }
          }
        }
        return true;
      } else {
        this.router.navigate(['/home']);
      }
  }

}
