import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsService } from '../../components.service';

@Component({
  selector: 'app-stats-dialog',
  templateUrl: './stats-dialog.component.html',
  styleUrls: ['./stats-dialog.component.css']
})
export class StatsDialogComponent implements OnInit {
  statsData: any;
  ok = true;
  constructor(public modal: NgbActiveModal, 
    private componentsService: ComponentsService ) { }

  ngOnInit() {
    this.statsData = this.componentsService.dialogDataSack;
  }

}
