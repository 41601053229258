
import {AuthToken} from '../../auth/model/auth-token.model';

export class User {

  public pseudo: string;
  public password: string;
  public authToken: any;
  public data: any;

  constructor(pseudo: string = null, password: string = null, authToken: any = null,
     data: any = null) {
    this.pseudo = pseudo;
    this.password = password;
    this.authToken = authToken;
    this.data = data;
  }

}
