import {Injectable} from '@angular/core';
import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable} from 'rxjs/index';

import {UserService} from '../user/user.service';

import {Subject} from 'rxjs/index';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import {GlobalVariable} from '../../global';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

import {SlimLoadingBarService} from '@cime/ngx-slim-loading-bar';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private userService: UserService, private authService: AuthService, 
    private slimLoadingBarService: SlimLoadingBarService,
    private router: Router ) { 
      console.log('Intercepting'); 
      this.slimLoadingBarService.color = 'red';
      this.slimLoadingBarService.height = '3px';
    }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('interception of request');
    // this.utilsService.showLoading();
    this.slimLoadingBarService.visible = true;
    this.slimLoadingBarService.start(() => {
      console.log('Loading complete');
    }); 

    let appReq = req;
    appReq = appReq.clone({headers: req.headers
      .set('X-APP-ID',  GlobalVariable.APP_UNIK_ID)
    });

    if (this.authService.isAuthenticated()) {
        appReq = appReq.clone({headers: req.headers
            .set('X-APP-ID',  GlobalVariable.APP_UNIK_ID)
            .set('X-AUTH-TOKEN', this.userService.getToken())
        });
    }
    return next.handle(appReq).pipe(
        map((event: HttpEvent<any>) => {
            this.slimLoadingBarService.complete();
            if (event instanceof HttpResponse) {
                if (event.body.message) {
                    if (event.body.message === 'invalid-token') {
                        this.userService.currentUser.next(null);
                        return null;
                    } else if (event.body.message === 'error_app') {
                        this.userService.currentUser.next(null);
                        return null;
                    }
                }
                // this.errorDialogService.openDialog(event);
            }
            return event;
        }),
        catchError((err: any, caught: Observable<any>) => {
            // console.log(err);
            this.slimLoadingBarService.complete();
            if (err.status === 0) {
                alert('Erreur de connexion au serveur')
            } else if (err.status === 401) {
              this.userService.currentUser.next(null);
              this.router.navigate(['/home']);
            }
            return throwError(err);
        })
    );
  }
}

