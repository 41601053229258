import { Component } from '@angular/core';
import { AlertBoxService } from './shared/components/alert-box/alert-box.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertBoxComponent } from './shared/components/alert-box/alert-box.component';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Kekeli';

  constructor( private alertBoxService: AlertBoxService, 
    private _modalService: NgbModal, private router: Router ) {

      this.router.events.subscribe(
        (event) => {
          if (event instanceof NavigationEnd ) {
            if (event.url) {
              window && window.scroll(0,0); 
            }
          }
        }
      );
    
  }
  
}
