import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse, HttpHeaders} from '@angular/common/http';
import {GlobalVariable} from '../../global';
import {Subject} from 'rxjs/index';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class DashboardLayoutService {
    statsUpdateSubject: Subject<any> = new Subject<any>();

    constructor(private http: HttpClient) {
    }

    getProfileData(pseudo) {
        return this.http.get(GlobalVariable.BASE_USER_API_URL + '/getProfileData.php', 
        {
            params: {
                pseudo: pseudo
            }
        }).pipe(
            map(
                ((response: HttpResponse<any>) => {
                        return response;
                    }
                )),
            catchError(
                (error: HttpErrorResponse) => {
                    return throwError({
                        status: error.status,
                        message: error.error.message
                    });
                }
            )
        );
    }


    getTestStats(pseudo) {
        return this.http.get(GlobalVariable.BASE_USER_API_URL + '/getTestStats.php', 
        {
            params: {
                pseudo: pseudo
            }
        }).pipe(
            map(
                ((response: HttpResponse<any>) => {
                        return response;
                    }
                )),
            catchError(
                (error: HttpErrorResponse) => {
                    return throwError({
                        status: error.status,
                        message: error.error.message
                    });
                }
            )
        );
    }
}   