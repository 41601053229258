import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-backend-search-input',
  templateUrl: './backend-search-input.component.html',
  styleUrls: ['./backend-search-input.component.css']
})
export class BackendSearchInputComponent implements OnInit {
  @Input('searchFormControl') searchFormControl: FormControl;
  @Input('placeholder') placeholder: string;
  @Input('label') label: string;
  @Output() anResultSelected: EventEmitter<any> = new EventEmitter<any>();
  search_results: any = [];
  constructor() { }

  ngOnInit() {
  }

  onKeyup(e) {
    e.preventDefault();
    if (e.target.value.length >= 3) {
      
    }
  }

}
