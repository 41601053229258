import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import { UserService } from '../../user/user.service';
import {Observable} from 'rxjs';

@Directive({
  selector: '[appHasPermission]'
})
export class HasPermissionDirective {
  currentUser:any;
  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private userService: UserService) {
    this.currentUser = this.userService.getCurrentUser();
  }

  @Input() set appHasPermission(permissionsAccepted: string[]) {

    const hasPermission: boolean = <boolean>permissionsAccepted.includes(this.currentUser.data.accountType);
    if (hasPermission) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }

  }

}
