
export class Stat {
    solde_keko: number;
    inMonth: number;
    inYear: number;
    life: number;
    validityDate: any;
    constructor(solde_keko = null, inMonth = null, inYear = null, life=null, validityDate = null) {
      this.solde_keko = solde_keko;
      this.inMonth = inMonth;
      this.inYear = inYear;
      this.life = life;
      this.validityDate = validityDate;
    }
  }