import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BackendSearchService } from '../../services/backend-search.service';


@Component({
  selector: 'app-state-auto-complete',
  templateUrl: './state-auto-complete.component.html',
  styleUrls: ['./state-auto-complete.component.css']
})
export class StateAutoCompleteComponent implements OnInit {
  @Input('stateFormControl') stateFormControl: FormControl;
  @Input('placeholder') placeholder: string;
  @Input('label') label: string;
  @Output() onStateResultSelected: EventEmitter<any> = new EventEmitter<any>();
  search_results: any = [];
  loading = false;
  constructor(private backendSearchService: BackendSearchService) { }

  ngOnInit() {
  }

  onKeyup(e) {
    e.preventDefault();
    if (e.target.value.length >= 3) {
      this.loading = true;
      this.backendSearchService.searchState({
        searchStr: e.target.value
      }).subscribe(
        (res) => {
          this.search_results = res;
          this.loading = false;
        }
      )
    }
  }

  selectItem(result) {
    this.onStateResultSelected.emit(result.nom);
    this.search_results = [];
  }

}
