import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterRoutes } from './footer.routing';
import { FooterComponent } from './footer.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(FooterRoutes),
    FormsModule,
    ReactiveFormsModule,
   ],
  declarations: [
      FooterComponent
  ],
  providers: [ 
   ],
  entryComponents: [
  ],
  exports: [
    FooterComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})

export class FooterModule {}
