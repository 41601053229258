import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs/index';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import {GlobalVariable} from '../../global';
import {Credentials} from './model/credentials.model';
import {User} from '../user/model/user.model';
import {AuthToken} from './model/auth-token.model';
import {UserService} from '../user/user.service';


@Injectable()
export class AuthService {

  // private loginUrl: string = GlobalVariable.BASE_API_URL + '/auth-tokens';

  constructor(private http: HttpClient,
              private userService: UserService) {
    const userDataStored = window.localStorage.getItem('currentUser');
    if (userDataStored === null) {
      this.userService.currentUser.next(null);
    }else {
      this.userService.currentUser.next(JSON.parse(userDataStored));
    }
  }

  signinUser(credentials: Credentials) {
    return this.http.post(GlobalVariable.BASE_USER_API_URL + '/login.php' , credentials)
      .pipe(
          map(
              ((response: any) => {
                    const user = new User(credentials.pseudo, null, response.authToken, response.data);
                    window.localStorage.clear();
                    window.localStorage.setItem('currentUser', JSON.stringify(user));
                    this.userService.currentUser.next(user);
                    return user;
                  }
              )),
          catchError(
              (error: HttpErrorResponse) => {
                  return throwError({
                      status: error.status,
                      message: error.error.message
                  });
              }
          )
      );
  }  

  signinParent(credentials: Credentials) {
    return this.http.post(GlobalVariable.BASE_USER_API_URL + '/login_parent.php' , credentials)
      .pipe(
          map(
              ((response: any) => {
                    const user = new User(credentials.pseudo, null, response.authToken, response.data);
                    window.localStorage.clear();
                    window.localStorage.setItem('currentUser', JSON.stringify(user));
                    this.userService.currentUser.next(user);
                    return user;
                  }
              )),
          catchError(
              (error: HttpErrorResponse) => {
                  return throwError({
                      status: error.status,
                      message: error.error.message
                  });
              }
          )
      );
  } 

  signOutUser() {
    window.localStorage.removeItem('currentUser');
    this.userService.currentUser.next(null);
  }

  // deleteToken(reference) {
  //   return this.http.delete(GlobalVariable.BASE_API_URL + '/auth-token/' + reference).map(
  //     (response) => {
  //       return response;
  //     }
  //   );
  // }

  isAuthenticated(): boolean {
    return this.userService.userExists();
  }

}
