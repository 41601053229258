import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth/auth.service';

import { CountupTimerService } from 'ngx-timer'
import { ComponentsService } from '../components.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StatsDialogComponent } from './stats-dialog/stats-dialog.component';
import { UserService } from 'src/app/shared/user/user.service';
import { Profile } from '../../shared/models/Profile';
import { Stat} from '../../shared/models/Stat';
import { GlobalVariable } from 'src/app/global';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    account: string[];
}


export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard',
     icon: 'fa-clipboard text-danger', class: '', account: ['student', 'demo', 'parent'] },
    { path: '/test', title: 'Nouveau Test',  icon: 'fa-play text-success',
     class: '', account: ['student', 'demo' ] },
    { path: '/statistiques', title: 'Statistiques',  icon: 'fa-chart-pie text-orange',
     class: '', account: ['student', 'demo', 'parent'] },
    { path: '/marche', 
    title: 'Marché',  icon: 'fa-shopping-cart text-yellow',
     class: '', account: ['student'] },
    { path: '/commandes', title: 'Commandes',  icon: 'fa-shopping-cart text-info',
     class: '', account: ['student'] },
    { path: '/payement', title: 'Payement', 
      icon: 'fa-credit-card text-danger',
     class: '', account: ['student', 'parent'] },
    { path: '/parametres',
      title: 'Paramètres', 
      icon: 'fa-cog text-success',
      class: '', account: ['student'] 
    }
    // { path: '/icons', title: 'Icons',  icon:'ni-planet text-blue', class: '' },
    // { path: '/maps', title: 'Maps',  icon:'ni-pin-3 text-orange', class: '' },
    // { path: '/user-profile', title: 'User profile',  icon:'ni-single-02 text-yellow', class: '' },
    // { path: '/tables', title: 'Tables',  icon:'ni-bullet-list-67 text-red', class: '' },
    // { path: '/login', title: 'Login',  icon:'ni-key-25 text-info', class: '' },
    // { path: '/register', title: 'Register',  icon:'ni-circle-08 text-pink', class: '' }
];



@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: RouteInfo[] = [];
  public isCollapsed = true;
  loading = false;
  @Input('statsData') statsData: Stat = new Stat();
  @Input('profileData') profileData: Profile = new Profile();
  currentUser: any;

  globals = GlobalVariable;
  
  constructor(private router: Router, private authService: AuthService, 
    private _modalService: NgbModal, private userService: UserService,
    private countupTimerService: CountupTimerService, private componentsService: ComponentsService ) {
      this.countupTimerService.stopTimer();
      this.currentUser = this.userService.getCurrentUser();
     }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => {
      return menuItem.account.includes(this.currentUser.data.accountType);
    });
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
   this.countupTimerService.startTimer();
  }

  logout() {
    this.loading = true;
    const current_user_account_type = this.currentUser.data.accountType
    this.authService.signOutUser();
    if (current_user_account_type === 'student') {
      this.router.navigate(['/connexion', 'student']);
    } else if (current_user_account_type === 'parent') {
      this.router.navigate(['/connexion', 'parent']);
    } else if (current_user_account_type === 'demo') {
      this.router.navigate(['/home']);
    }
  }

  showStats() {
    this.componentsService.dialogDataSack = this.statsData;
    this._modalService.open( StatsDialogComponent ).result.then(
      (res) => {
      }
    );
  }
}
