import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  menus = [
    {
      title: 'Accueil',
      icon: '',
      submenu: [],
      url: '/home',
      active: true
    },
    {
      title: 'Savoir plus',
      icon: '',
      submenu: [],
      url: '/savoir-plus',
      active: false
    },
    {
      title: 'Inscription',
      icon: '',
      submenu: [],
      url: '/register',
      active: false
    },
    {
      title: 'Connexion',
      icon: '',
      submenu: [],
      url: '/connexion',
      active: false
    },
    {
      title: 'Nous contacter',
      icon: '',
      submenu: [],
      url: '/contact',
      active: false
    }
  ];
  active_url = '/home';
  constructor(private route: ActivatedRoute, private router: Router ) {
    // this.route
    this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd ) {
          this.active_url = this.router.url;
          console.log(this.active_url);
        }
      }
    )
   }

  ngOnInit() {
  }

  onMenuClick(m) {
    this.menus.forEach(
      (menu) => {
        if (menu.active) {
          menu.active = false;
        }
        if (menu.title === m.title) {
          menu.active = true;
        } 
      }
    );
  }

  getActiveMenu(menu) {
    return this.active_url.search(menu.url) >= 0;
  }

}
