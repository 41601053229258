import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertBoxService } from './alert-box.service';

@Component({
  selector: 'app-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.css']
})
export class AlertBoxComponent implements OnInit {
  ok: boolean = true;
  constructor(public modal: NgbActiveModal, private _modalService: NgbModal, 
    public alertBoxService: AlertBoxService) { 
    }

  ngOnInit() { }

  

}
