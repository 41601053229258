import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { DashboardFooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxTimerModule } from 'ngx-timer';
import { StatsDialogComponent } from './sidebar/stats-dialog/stats-dialog.component';
import { ComponentsService } from './components.service';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgxTimerModule,
    NgbModule,
    NgbAlertModule,
    SharedModule
  ],
  declarations: [
    DashboardFooterComponent,
    NavbarComponent,
    SidebarComponent,
    StatsDialogComponent
  ],
  entryComponents: [
    StatsDialogComponent
  ],
  exports: [
    DashboardFooterComponent,
    NavbarComponent,
    SidebarComponent
  ],
  providers: [
    ComponentsService
  ]
})
export class ComponentsModule { }
