import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderModule } from './header/header.module';
import { FooterModule } from './footer/footer.module';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { AuthService } from './shared/auth/auth.service';
import { UserService } from './shared/user/user.service';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { AuthInterceptorService } from './shared/auth/auth-interceptor.service';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { StudentLayoutComponent } from './layouts/student-layout/student-layout.component';

import { ComponentsModule } from './components/components.module';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { DashboardLayoutService } from './layouts/dashboard-layout/dashboard-layout.service';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertBoxService } from './shared/components/alert-box/alert-box.service';
import {SlimLoadingBarModule} from '@cime/ngx-slim-loading-bar';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NotFoundLayoutComponent } from './layouts/not-found-layout/not-found-layout.component';

import { PopupPubsService } from './shared/components/popup-pubs/popup-pubs.service';


@NgModule({
  declarations: [
    AppComponent,
    HomeLayoutComponent,
    StudentLayoutComponent,
    DashboardLayoutComponent,
    NotFoundLayoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HeaderModule,
    FooterModule,
    HttpClientModule,
    SharedModule,
    ComponentsModule,
    NgxDatatableModule,
    NgbModule,
    SlimLoadingBarModule.forRoot(),
    ImageCropperModule
  ],
  providers: [
    AuthService,
    UserService,
    AuthGuard,
    DashboardLayoutService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    AlertBoxService,
    PopupPubsService
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
