import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header.component';
import { HeaderRoutes } from './header.routing';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild( HeaderRoutes ),
    FormsModule,
    ReactiveFormsModule,
   ],
  declarations: [
    HeaderComponent
  ],
  providers: [ 
   ],
  entryComponents: [
  ],
  exports: [
    HeaderComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})

export class HeaderModule {}
