import { Routes } from '@angular/router';
import { FooterComponent } from './footer.component';


export const FooterRoutes: Routes = [
  {
    path: '',
    children: [{
      path: '',
      component: FooterComponent
    }
  ]
  }
];
