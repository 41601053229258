import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalVariable } from 'src/app/global';
import { PopupPubsService } from './popup-pubs.service';

@Component({
  selector: 'app-popup-pubs',
  templateUrl: './popup-pubs.component.html',
  styleUrls: ['./popup-pubs.component.css']
})
export class PopupPubsComponent implements OnInit {
  globals = GlobalVariable ;
  pubs: any = [];
  showNavigationArrows: boolean = true;
  showNavigationIndicators: boolean = true; paused = false;
  
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  pauseOnFocus = true;
  
  constructor(public modal: NgbActiveModal,  private popupPubsService: PopupPubsService ) { 
    
  }

  ngOnInit() {
    this.pubs = this.popupPubsService.pubs;
    console.log(this.pubs);
  }

}
