import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BackendSearchService } from '../../services/backend-search.service';


@Component({
  selector: 'app-town-auto-complete',
  templateUrl: './town-auto-complete.component.html',
  styleUrls: ['./town-auto-complete.component.css']
})
export class TownAutoCompleteComponent implements OnInit {
  @Input('townFormControl') townFormControl: FormControl;
  @Input('placeholder') placeholder: string;
  @Input('label') label: string;
  @Output() onTownResultSelected: EventEmitter<any> = new EventEmitter<any>();
  search_results: any = [];
  loading = false;
  constructor(private backendSearchService: BackendSearchService ) { }

  ngOnInit() {
  }

  onKeyup(e) {
    e.preventDefault();
    if (e.target.value.length >= 3) {
      this.loading = true;
      this.backendSearchService.searchTown({
        searchStr: e.target.value
      }).subscribe(
        (res) => {
          this.search_results = res;
          this.loading = false;
        }
      )
    }
  }

  selectItem(result) {
    this.onTownResultSelected.emit(result.nom);
    this.search_results = [];
  }

}
