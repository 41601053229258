import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse, HttpHeaders} from '@angular/common/http';
import {GlobalVariable} from '../global';
import {Subject} from 'rxjs/index';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class ComponentsService {
    stepSubject: Subject<any> = new Subject<any>();
    dialogDataSack: any;

    constructor(private http: HttpClient) {
    }
}    