import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { Stat} from '../../shared/models/Stat';
import { Profile } from '../../shared/models/Profile';
import { GlobalVariable } from 'src/app/global';
import { UserService } from 'src/app/shared/user/user.service';
import { AuthService } from 'src/app/shared/auth/auth.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Input('statsData') statsData: Stat = new Stat();
  @Input('profileData') profileData: Profile = new Profile();
  public focus;
  public listTitles: any[];
  public location: Location;
  globals = GlobalVariable;
  currentUser: any;
  constructor(location: Location,  private element: ElementRef,
     private router: Router, private userService: UserService,
     private authService: AuthService ) {
    this.location = location;
    this.currentUser = this.userService.getCurrentUser();
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
  }
  getTitle(){
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if(titlee.charAt(0) === '#'){
        titlee = titlee.slice( 1 );
    }

    for(var item = 0; item < this.listTitles.length; item++) {
      
      if(titlee.indexOf(this.listTitles[item].path) >= 0) {
          return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }

  logout() {
    // this.loading = true;
    const current_user_account_type = this.currentUser.data.accountType
    this.authService.signOutUser();
    if (current_user_account_type === 'student') {
      this.router.navigate(['/connexion', 'student']);
    } else if (current_user_account_type === 'parent') {
      this.router.navigate(['/connexion', 'parent']);
    } else if (current_user_account_type === 'demo') {
      this.router.navigate(['/home']);
    }
  }

  /*
    <form class="form-inline">
      <div class="form-group">
        <div class="input-group">
          <input class="form-control" placeholder="yyyy-mm-dd"
                name="dp" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
          </div>
        </div>
      </div>
    </form>
    ----------------------------------------
    -------------------------------------------------------------------------------
    import {Component} from '@angular/core';
    import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

    @Component({
      selector: 'ngbd-datepicker-popup',
      templateUrl: './datepicker-popup.html'
    })
    export class NgbdDatepickerPopup {
      model: NgbDateStruct;
    }
  */

}
