import {Subject} from 'rxjs/index';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {User} from './model/user.model';
import {AuthToken} from '../auth/model/auth-token.model';


@Injectable()
export class UserService {

  public currentUser: Subject<User> = new Subject<User>();
  private currentUserData: User;

  constructor() {
    this.currentUser.subscribe(
      (user: User) => {
        this.currentUserData = user;
      }
    );
  }
  userExists(): boolean {
    return (this.currentUserData !== null);
  }

  getToken(): any {
    return this.currentUserData.authToken;
  }

  getCurrentUser(): User {
    return Object.assign({}, this.currentUserData);
  }

  getCurrentUserAuthToken(): AuthToken {
    return Object.assign({}, this.currentUserData.authToken);
  }

  getUserPermissions(): string[] {
    const currentUser: any = JSON.parse(window.localStorage.getItem('currentUser'));
    const perms = [];
    if (currentUser) {
      if (currentUser.hasOwnProperty('permissions')) {
          currentUser.permissions.forEach(function(permission: any) {
          perms.push(permission.name);
        });
      }
    }
    return perms;
  }

  userHasPermission(str): boolean {
    const permissions = this.getUserPermissions();
    return permissions.includes(str);
  }
}
