import { Injectable } from '@angular/core';

import {Subject} from 'rxjs/index';


@Injectable({
    providedIn: 'root'
})
export class AlertBoxService {
    openCloseSubject: Subject<any> = new Subject<any>();
    alertType: string = 'success';
    alertTitle: string;
    alertMessage: string;
    alertIsConfirm: boolean = false;
    ok = true;
    constructor() {
    }

    defineAlert(title, message, type ='success', alertIsConfirm = false) {
        
        this.alertType = type;
        this.alertTitle = title;
        this.alertMessage = message;
        this.alertIsConfirm  = alertIsConfirm;

    }



}   