import { Injectable } from '@angular/core';

import {Subject} from 'rxjs/index';


@Injectable({
    providedIn: 'root'
})
export class PopupPubsService {
    openCloseSubject: Subject<any> = new Subject<any>();
    pubs: any = [];
    ok = true;
    constructor() {
    }

    definePubs(pubs) {
        this.pubs = pubs;
    }

}   