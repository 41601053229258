import { Component, OnInit } from '@angular/core';
import { DashboardLayoutService } from './dashboard-layout.service';
import { UserService } from 'src/app/shared/user/user.service';
import { Profile } from '../../shared/models/Profile';
import { Stat } from '../../shared/models/Stat';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.css']
})

export class DashboardLayoutComponent implements OnInit {
  profileData: Profile = new Profile();
  statsData: Stat = new Stat();
  currentUser: any;
  constructor( private dashboardLayoutService: DashboardLayoutService, 
    private userService: UserService ) {
    window.scrollTo(0, 0);
    this.currentUser = this.userService.getCurrentUser();

    this.getProfileData();
    this.getTestStats();

    this.dashboardLayoutService.statsUpdateSubject.asObservable().subscribe(
      (res) => {
        if (res) {
          this.getTestStats();
          this.getProfileData();
        }
      }
    )
   }

  
  getProfileData() {
    // if (!this.profileData) {
      this.dashboardLayoutService.getProfileData(this.currentUser.pseudo).subscribe(
        (res: any) => {
          this.profileData = res.profile;
        }
      );
  }
  getTestStats() {
    // if (!this.statsData) {
      this.dashboardLayoutService.getTestStats(this.currentUser.pseudo).subscribe(
        (res: any) => {
          this.statsData = res.stats;
        }
      );
  }

  ngOnInit() {
  }

}
