import { Routes } from '@angular/router';
import { HeaderComponent } from './header.component';


export const HeaderRoutes: Routes = [
  {
    path: '',
    children: [{
      path: '',
      component: HeaderComponent
    }
  ]
  }
];
