import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BackendSearchInputComponent } from './components/backend-search-input/backend-search-input.component';
import { BackendSearchInputService } from './components/backend-search-input/backend-search-input.service';
import { SchoolAutoCompleteComponent } from './components/school-auto-complete/school-auto-complete.component';
import { TownAutoCompleteComponent } from './components/town-auto-complete/town-auto-complete.component';
import { StateAutoCompleteComponent } from './components/state-auto-complete/state-auto-complete.component';
import { BackendSearchService } from './services/backend-search.service';
import { PhotoBlockComponent } from './components/photo-block/photo-block.component';
import { MediaService } from './media/media.service';
import { AlertBoxComponent } from './components/alert-box/alert-box.component';
import { HasPermissionDirective } from './directives/has-permission/has-permission.directive';
import { CurrencyFormatPipePipe } from './pipes/CurrencyFormatPipe/currency-format-pipe.pipe';
import { PhotoBlockDialogComponent } from './components/photo-block/photo-block-dialog/photo-block-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { RegexpInputCheckDirective } from './directives/regexp-input-check/regexp-input-check.directive';
import { PopupPubsComponent } from './components/popup-pubs/popup-pubs.component';
import { NgbCarouselModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    NgbCarouselModule,
    NgbModalModule
  ],
  declarations: [
    BackendSearchInputComponent,
    SchoolAutoCompleteComponent,
    TownAutoCompleteComponent,
    StateAutoCompleteComponent,
    PhotoBlockComponent,
    AlertBoxComponent,
    HasPermissionDirective,
    CurrencyFormatPipePipe,
    PhotoBlockDialogComponent,
    RegexpInputCheckDirective,
    PopupPubsComponent
  ],
  exports: [
    BackendSearchInputComponent,
    SchoolAutoCompleteComponent,
    TownAutoCompleteComponent,
    StateAutoCompleteComponent,
    PhotoBlockComponent,
    HasPermissionDirective,
    CurrencyFormatPipePipe,
    RegexpInputCheckDirective
   ],
  entryComponents: [
    AlertBoxComponent,
    PhotoBlockDialogComponent,
    PopupPubsComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
      BackendSearchInputService,
      BackendSearchService,
      MediaService,
      CurrencyFormatPipePipe
  ]
})
export class SharedModule { }
