import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/index';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {GlobalVariable} from '../../global';
import {Media} from './media.model';


@Injectable()
export class MediaService {
  photoFile: any = null;
  photoEvent: any = null;
  constructor(private http: HttpClient) {
  }

  getMedia (reference: string) {

    return this.http.get(GlobalVariable.BASE_USER_API_URL + '/getMedia.php').pipe(
      map(
          ((response: HttpResponse<any>) => {
                  return response;
              }
          )),
      catchError(
          (error: HttpErrorResponse) => {
              return throwError({
                  status: error.status,
                  message: error.error.message
              });
          }
      )
  );

  }

  postMedia (file) {

    // const body = new FormData();
    // body.append('file', file);

    const body = {
        'file': file
    };

    return this.http.post(GlobalVariable.BASE_USER_API_URL + '/uploadMedia.php',
     body).pipe(
      map(
          ((response: HttpResponse<any>) => {
                  return response;
              }
          )),
      catchError(
          (error: HttpErrorResponse) => {
              return throwError({
                  status: error.status,
                  message: error.error.message
              });
          }
      )
     );
  }

}
