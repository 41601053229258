import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MediaService } from 'src/app/shared/media/media.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-photo-block-dialog',
  templateUrl: './photo-block-dialog.component.html',
  styleUrls: ['./photo-block-dialog.component.css']
})
export class PhotoBlockDialogComponent implements OnInit {
  photoFile: any;
  imageChangedEvent: any;
  croppedImage: any = '';

  constructor(public modal: NgbActiveModal, private mediaService: MediaService ) {
    console.log(this.mediaService);
    this.imageChangedEvent = this.mediaService.photoEvent;
   }

  ngOnInit() {
    
  }

  crop() {
    this.modal.close(this.croppedImage);
  }

  fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

  imageCropped(event: ImageCroppedEvent) {
     // console.log(event);
      this.croppedImage = event.base64;
    }

  imageLoaded() {
        // show cropper
    }

  cropperReady() {
        // cropper ready
    }
    
  loadImageFailed() {
        // show message
    }

}
