import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {MediaService} from '../../media/media.service';
import {FormControl} from '@angular/forms';
import {Media} from '../../media/media.model';
import {GlobalVariable} from '../../../global';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PhotoBlockDialogComponent } from './photo-block-dialog/photo-block-dialog.component';

@Component({
  selector: 'app-photo-block',
  templateUrl: './photo-block.component.html',
  styleUrls: ['./photo-block.component.scss']
})
export class PhotoBlockComponent implements OnInit {

  @Input('photoFormControl') photoFormControl: FormControl;
  @Input() placeholder: string;
  @Output() onMediaSelected: EventEmitter<Media> = new EventEmitter<Media>();

  mediaUrl: any = null;
  mediaLoading: boolean = false;
  mediaBaseUrl = GlobalVariable.BASE_MEDIA_URL;

  onUploading: boolean = false;

  constructor(private mediaService: MediaService, private _modalService: NgbModal) { }

  ngOnInit() {
    this.photoFormControl.registerOnChange(
      (value) => {
        this.checkFormValue();
      }
    );
    this.checkFormValue();
  }

  checkFormValue() {
    if (this.photoFormControl.value !== null) {
      if (this.photoFormControl.value !== null && this.photoFormControl.value !== undefined) {
        this.mediaUrl = this.mediaBaseUrl + this.photoFormControl.value;
      } else {
        this.mediaUrl = this.photoFormControl.value;
      }
    } else {
      this.mediaUrl = null;
    }
  }

  onChangeMedia(e) {
    // const reader = new FileReader();
    // const file = e.target.files[0];

    this.mediaService.photoEvent = e;

    this._modalService.open( PhotoBlockDialogComponent ).result.then(
      (res) => {
        if (res) {
          // console.log(res);
          this.onUploading = true;
          this.mediaService.postMedia(res).subscribe(
            (response: any) => {
              this.photoFormControl.setValue(response.filename);
              this.mediaLoading = false;
              e.target.value = null;
              this.onMediaSelected.emit(this.photoFormControl.value);
              this.onUploading  = false;
            }, (err) => {
              this.onUploading  = false;
              alert("Erreur lors de l'envoie du fichier, réesseyez s'il vous plaît.")
            }
          );
        }
      }
    );

    // if (file === 'undefined' || file === null) {
    //   this.mediaUrl = null;
    // } else {
    //   this.mediaLoading = true;
    //   reader.onloadend = () => {
    //     this.mediaUrl = reader.result;
    //   };
    //   reader.readAsDataURL(file);

      
    // }

  }

  onDeleteMedia() {
    this.mediaUrl = null;
    this.photoFormControl.setValue(null);
    this.mediaLoading = false;
    this.onMediaSelected.emit(null);
  }

}
